<template>
  <b-row>
    <b-col
      md="12"
      sm="12"
      class="mb-2"
    >
      <h3>Business Statistics</h3>
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="UsersIcon"
        color="success"
        :statistic="dashboard_statistics.total_members"
        statistic-title="Total Members"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="UsersIcon"
        color="success"

        :statistic="dashboard_statistics.total_vendors"
        statistic-title="Total Vendor"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="BriefcaseIcon"
        color="success"

        :statistic="dashboard_statistics.total_businesses"
        statistic-title="Total Businesses"
      />
    </b-col>
    <!-- <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="MessageCircleIcon"
        color="success"

        :statistic="dashboard_statistics.total_business_reviews"
        statistic-title="Reviews"
      />
    </b-col> -->

    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="SearchIcon"
        color="success"
        :statistic="dashboard_statistics.total_business_searches"
        statistic-title="Total Searches"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="StarIcon"
        color="success"

        :statistic="dashboard_statistics.total_business_reviews"
        statistic-title="Total Reviews"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="AwardIcon"
        color="success"

        :statistic="dashboard_statistics.total_businesses_recommended"
        statistic-title="Total Recommendations"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
    >
      <statistic-card-horizontal
        icon="MailIcon"
        color="success"

        :statistic="dashboard_statistics.total_messages_sent_to_vendors"
        statistic-title="Total Messages Sent to Vendors"
      />
    </b-col>
    <b-col
      md="4"
      sm="6"
      style="cursor: pointer;"
      v-if="dashboard_statistics.total_bad_reviews > 0"
    >
      <b-card
        bg-variant="light-danger"
        class="stats-review-card"
        no-body
        @click="$router.push({ name : 'bad-reviews'}).catch(() => {})"
      >
        <b-card-body
          class="d-flex justify-content-between align-items-center"
        >
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{dashboard_statistics.total_bad_reviews}}
            </h2>
            <span>Bad Reviews</span>
          </div>
          <b-avatar
            :variant="`light-danger`"
            size="45"
          >
            <feather-icon
              size="21"
              icon="StarIcon"
            />
          </b-avatar>
        </b-card-body>
      </b-card>
      <!-- <statistic-card-horizontal
        icon="MailIcon"
        color="success"
        :bg="danger"
        :statistic="dashboard_statistics.total_messages_sent_to_vendors"
        statistic-title="Reported Reviews"
      /> -->
    </b-col>
    <b-col
      md="4"
      sm="6"
      style="cursor: pointer;"
      v-if="dashboard_statistics.total_blocked_businesses > 0"
    >
      <b-card
        bg-variant="light-danger"
        class="stats-review-card"
        no-body
        @click="$router.push({ name : 'suspended-business'}).catch(() => {})"
      >
        <b-card-body
          class="d-flex justify-content-between align-items-center"
        >
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{dashboard_statistics.total_blocked_businesses}}
            </h2>
            <span>Suspended Business</span>
          </div>
          <b-avatar
            :variant="`light-danger`"
            size="45"
          >
            <feather-icon
              size="21"
              icon="BriefcaseIcon"
            />
          </b-avatar>
        </b-card-body>
      </b-card>
      <!-- <statistic-card-horizontal
        icon="MailIcon"
        color="success"
        :bg="danger"
        :statistic="dashboard_statistics.total_messages_sent_to_vendors"
        statistic-title="Reported Reviews"
      /> -->
    </b-col>

    <b-col
      md="4"
      sm="6"
      v-if="dashboard_statistics.total_reported_reviews > 0"
      style="cursor: pointer;"
    >
      <b-card
        bg-variant="light-danger"
        class="stats-review-card"
        no-body
        @click="showReportedReviews"
      >
        <b-card-body
          class="d-flex justify-content-between align-items-center"
        >
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{dashboard_statistics.total_reported_reviews}}
            </h2>
            <span>Reported Reviews</span>
          </div>
          <b-avatar
            :variant="`light-danger`"
            size="45"
          >
            <feather-icon
              size="21"
              icon="AlertTriangleIcon"
            />
          </b-avatar>
        </b-card-body>
      </b-card>
      <!-- <statistic-card-horizontal
        icon="MailIcon"
        color="success"
        :bg="danger"
        :statistic="dashboard_statistics.total_messages_sent_to_vendors"
        statistic-title="Reported Reviews"
      /> -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BCardBody, BAvatar,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardBody,
    BAvatar,
    StatisticCardHorizontal,
  },
  data() {
    return {
      newsletter: {},
    }
  },
  computed: {
    dashboard_statistics() {
      return this.$store.state.statistic.dashboard_statistics
    },
    member_statistics() {
      return this.$store.state.statistic.member_statistics
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      this.$store.dispatch('statistic/getStatistics')
    },
    showReportedReviews() {
      this.$router.push({ name: 'reported-reviews' }).catch(() => {})
    },
  },
}
</script>

<style scoped>

</style>
